<template>
	<div>
		<div class="leeting-listing">
			<div class="overflow-y" style="max-height: calc(100vh - 245px)">
				<table
					width="100%"
					class="listing-table table-head-sticky"
					style="border-spacing: 0; border-collapse: initial"
				>
					<thead>
						<tr>
							<th class="p-2 light-blue-bg">Time</th>
							<th class="p-2 light-blue-bg" width="300">Title</th>
							<!-- <th class="p-2 light-blue-bg">Related To</th> -->
							<th class="p-2 light-blue-bg" width="200">Location</th>
							<th class="p-2 light-blue-bg">Reminder</th>
							<th class="p-2 light-blue-bg">Created At</th>
							<th class="p-2 light-blue-bg text-center" width="50">Action</th>
						</tr>
					</thead>
					<!-- <pre>
						{{meetingData}}
					</pre> -->
					<!-- {{meetingData}} -->
					<tbody v-if="meetingData">
						<template v-for="(row, index) in meetingData">
							<tr :key="'date-' + index" v-if="!index == ''" :class="index ? 'active_class' : ''">
								<td colspan="7" class="p-2 border-top-light-grey">
									<div>
										<v-icon color="blue" class="me-1" small>mdi-calendar</v-icon
										><span class="font-level-2-bold text-capitalize" v-if="index === 'today'">
											{{ formatDate(todatDate) }} ({{ index }})</span
										>
										<span class="font-level-2-bold text-capitalize" v-else-if="index === 'yesterday'">
											{{ formatDate(yesterdayDate) }} ({{ index }})</span
										>

										<span class="font-level-2-bold text-capitalize" v-else> ({{ index }})</span>
									</div>
								</td>
							</tr>
							<template v-for="rowChild in row">
								<tr :key="rowChild?.id">
									<!-- <pre>{{row}}</pre> -->

									<td
										class="p-2 border-top-light-grey"
										:style="`border-left: 8px solid ${getStatusColor(rowChild.status)}`"
									>
										<div class="d-flex align-center">
											<v-icon small>mdi-clock-outline</v-icon
											><span class="font-weight-bold text-h5 ms-1">{{
												formatTime22(rowChild.start_time)
											}}</span>
										</div>

										<span v-if="rowChild && rowChild.meeting_type">
											<template v-if="rowChild && rowChild.meeting_type == 'other'">
												<div class="d-flex align-center">
													<v-img max-width="20" cover src="/media/logos/zoom-icon.png"></v-img>
													<v-chip
														class="ml-2 text-capitalize"
														color="blue white--text"
														outlined
														x-small
														label
														v-if="rowChild && rowChild.meeting_type"
														>Zoom
													</v-chip>
												</div>
											</template>
											<template v-if="rowChild && rowChild.meeting_type == 'bthrust'">
												<div class="d-flex align-center">
													<v-img max-width="15" contain src="/favicon-bthrust.ico"></v-img>
													<v-chip
														class="ml-2 text-capitalize"
														color="red white--text"
														x-small
														outlined
														label
														v-if="rowChild && rowChild.meeting_type"
														>Office
													</v-chip>
												</div>
											</template>
											<template v-if="rowChild && rowChild.meeting_type == 'client'">
												<v-icon small>mdi-map-marker</v-icon>
												<v-chip
													class="ml-2 text-capitalize"
													color="green white--text"
													outlined
													x-small
													label
													v-if="rowChild && rowChild.meeting_type"
													>F2F Client
												</v-chip>
											</template>
											<template v-if="rowChild && rowChild.meeting_type == 'other2'">
												<v-icon small>mdi-map-marker</v-icon>
												<v-chip
													class="ml-2 text-capitalize"
													color="orange white--text"
													x-small
													outlined
													label
													v-if="rowChild && rowChild.meeting_type"
													>Other
												</v-chip>
											</template>
										</span>
									</td>
									<td class="p-2 border-top-light-grey">
										<div class="font-level-3-bold text-capitalize">{{ rowChild.title }}</div>
										<div class="v-avatar-group">
											<v-tooltip top v-for="(item, index) in rowChild.attendies" :key="index">
												<template v-slot:activator="{ on, attrs }">
													<v-avatar size="32" v-bind="attrs" v-on="on">
														<img src="https://avatars0.githubusercontent.com/u/9064066?v=4&s=460" />
													</v-avatar>
												</template>
												<span>{{ item.display_name }}</span>
											</v-tooltip>
										</div>
									</td>
									<!-- <td class="p-2 border-top-light-grey">
										<div class="d-flex pb-1">
											{{rowChild.related_to }}
											
									</td> -->
									<td class="p-2 border-top-light-grey">
										<div v-if="rowChild && rowChild.location" class="cursor-pointer d-flex align-baseline">
											<v-icon small>mdi-map-marker</v-icon
											><span v-on:click="copyNumber(rowChild.location, 'Location')" class="text_climp">{{
												rowChild.location
											}}</span>
										</div>
									</td>
									<td class="p-2 border-top-light-grey">
										<div @click="open_reminder_drawer(rowChild.id)" class="blue--text cursor-pointer">
											<v-icon color="blue" small class="me-1">mdi-calendar</v-icon
											><span>{{ formatDate(rowChild?.reminders?.start_date) }}</span>
										</div>
									</td>
									<td class="p-2 border-top-light-grey">
										<v-chip outlined color="green" label>{{ formatTime2(rowChild.added_at) }}</v-chip>
									</td>
									<td class="p-2 border-top-light-grey text-center">
										<v-menu offset-y left max-width="150">
											<template v-slot:activator="{ on, attrs }">
												<v-btn depressed fab x-small color="default" v-bind="attrs" v-on="on">
													<v-icon>mdi-dots-vertical</v-icon>
												</v-btn>
											</template>
											<v-list dense>
												<v-list-item
													v-for="(item, index) in actionList"
													:key="'action-' + index"
													:value="index"
													@click="openAction(item, rowChild.id)"
												>
													<v-list-item-title class="cursor-pointer">
														<v-icon small class="me-2">{{ item.icon }}</v-icon>
														{{ item.title }}
													</v-list-item-title>
												</v-list-item>
											</v-list>
										</v-menu>
									</td>
								</tr>
							</template>
						</template>
					</tbody>
					<tfoot v-else>
						<tr>
							<td colspan="6">
								<p class="m-0 row-not-found text-center">
									<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
									Uhh... There are no meetings at the moment.
								</p>
							</td>
						</tr>
					</tfoot>
				</table>
			</div>
		</div>
		<CreateOrUpdateReminderV2
			v-if="open_dialog.reminder"
			:drawer="open_dialog.reminder"
			related="lead"
			:reminderId="edit_reminder_id"
			:relatedId="lead_id"
			v-on:close="closeReminderDialog()"
		>
		</CreateOrUpdateReminderV2>
	</div>
</template>
<script>
import MomentJS from "moment-timezone";
import MainMixin from "@/core/mixins/main.mixin";

import { SET_MESSAGE } from "@/core/services/store/common.module";
export default {
	name: "MeetingTab",
	mixins: [MainMixin],

	data() {
		return {
			delURL: null,
			yesterdayDate: MomentJS().subtract(1, "days").format("YYYY-MM-DD"),
			todatDate: MomentJS().format("YYYY-MM-DD"),
			delTEXT: null,
			confirm_dialog: false,
			open_dialog: {
				reminder: false,
			},
			actionList: [
				{ title: "View ", value: "view", icon: "mdi-eye" },
				{ title: "Edit ", value: "edit", icon: "mdi-pencil" },
				// { title: "Delete", value: "delete", icon: "mdi-delete" },
			],
			// meetingData: [
			// 	{
			// 		id: 1,
			// 		title: "Business Related",
			// 		status: "1",
			// 		meeting_type: "Zoom",
			// 		meeting_date: "06/06/2024",
			// 		meeting_day: "Today",
			// 		meeting_time: "9:30 AM",
			// 		modified_by: "06/06/2024",
			// 		related_to: "Lead",
			// 		contact_name: "Johson",
			// 		company_name: "Allzone Limousine",
			// 		lead_customer_name: "James ke",
			// 		location: "72 Bendemeer Rd #07-03 Luzerne S(), 28 Lor 23 Geylang, 00074, singapore, 11002",
			// 		reminder: "06/06/2024",
			// 	},
			// 	{
			// 		id: 2,
			// 		title: "Business Related",
			// 		status: "2",
			// 		meeting_type: "F/F",
			// 		meeting_date: "",
			// 		meeting_time: "11:30 AM",
			// 		modified_by: "06/06/2024",
			// 		related_to: "Internal",
			// 		lead_customer_name: "James ke",
			// 		contact_name: "John Doe",
			// 		company_name: "Omni Dental Centre",
			// 		location: "60 Paya Lebar Road, #07-54 Paya Lebar Square 409051 singapore",
			// 		reminder: "06/06/2024",
			// 	},
			// 	{
			// 		id: 3,
			// 		title: "Cricbuzz Meeting",
			// 		status: "3",
			// 		meeting_type: "Other",
			// 		meeting_date: "",
			// 		meeting_day: "",
			// 		meeting_time: "10:30 AM",
			// 		modified_by: "06/06/2024",
			// 		related_to: "Lead",
			// 		lead_customer_name: "James ke",
			// 		contact_name: "maxwell",
			// 		company_name: "Harmony Gem Crystals",
			// 		location: "72 Bendemeer Rd #07-03 Luzerne S(), 28 Lor 23 Geylang, 00074, singapore, 11002",
			// 		reminder: "06/06/2024",
			// 	},
			// 	{
			// 		id: 4,
			// 		title: "Cricbuzz Meeting",
			// 		status: "4",
			// 		meeting_type: "Office",
			// 		meeting_date: "04/06/2024",
			// 		meeting_day: "Yesterday",
			// 		meeting_time: "12:30 PM",
			// 		modified_by: "06/06/2024",
			// 		related_to: "Customer",
			// 		lead_customer_name: "James ke",
			// 		contact_name: "John Doe",
			// 		company_name: "Music Brio Studio LLP",
			// 		location: "8 Burn Road, #07-16 Trivex, singaore 369977",
			// 		reminder: "06/06/2024",
			// 	},
			// 	{
			// 		id: 5,
			// 		title: "Cricbuzz Meeting",
			// 		status: "3",
			// 		meeting_type: "Office",
			// 		meeting_date: "",
			// 		meeting_day: "",
			// 		meeting_time: "12:30 PM",
			// 		modified_by: "06/06/2024",
			// 		related_to: "Customer",
			// 		lead_customer_name: "James ke",
			// 		contact_name: "John Doe",
			// 		company_name: "Music Brio Studio LLP",
			// 		location: "8 Burn Road, #07-16 Trivex, singaore 369977",
			// 		reminder: "06/06/2024",
			// 	},
			// ],
		};
	},
	props: {
		meetingData: {
			type: [Object, Array],
			default: null,
		},
	},
	methods: {
		open_reminder_drawer(id) {
			this.open_dialog.reminder = true;
			this.edit_reminder_id = id;
		},
		closeReminderDialog() {
			this.open_dialog.reminder = false;
			this.edit_reminder_id = 0;
		},
		copyNumber(data, type) {
			if (type == "phone") {
				data = data.split("-").pop();
			}
			navigator.clipboard.writeText(data);
			this.$store.commit(SET_MESSAGE, [
				{ model: true, message: `${type} <b>${data}</b> - successfully coppied.` },
			]);
		},
		openAction(action, id) {
			switch (action.value) {
				case "view":
					this.$router.push({ name: "meeting-detail", params: { id: id } });
					break;
				case "edit":
					
					this.$emit("editMeeting", id);
					this.$emit("editTitle", "Update");
					// isConfDialog.value = true
					break;
				case "delete":
					break;
			}
		},
		getStatusColor(status) {
			if (status == 1) {
				return "green";
			}
			if (status == 2) {
				return "red";
			}
			if (status == 3) {
				return "blue";
			}
			if (status == 4) {
				return "grey";
			}
			return "cyan";
		},
		getStatusText(status) {
			if (status == 1) {
				return "Completed";
			}
			if (status == 2) {
				return "Cancelled";
			}
			if (status == 3) {
				return "Postponed";
			}
			if (status == 4) {
				return "Upcoming";
			}
		},
	},
	components: {
		CreateOrUpdateReminderV2: () => import("@/view/pages/leads/create/CreateOrUpdateReminderV2"),
	},
};
</script>

<style scoped>
tr.active_class {
	background-color: antiquewhite;
}
</style>
